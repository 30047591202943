import { DecimalPipe, NgFor, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'm3-calc-total',
    templateUrl: './total.component.html',
    standalone: true,
    imports: [NgFor, NgIf, DecimalPipe, FontAwesomeModule],
})
export class M3TotalComponent {
    @Input({ required: true }) form
    @Input({ required: true }) m3Object
    @Input({ required: true }) language
    @Input() listItems = false
    @Input() admin

    constructor(private toastrService: ToastrService) {}

    async fromClipboard() {
        const value = await navigator.clipboard.readText()
        try {
            const jsonValue = JSON.parse(value)
            this.form.patchValue(jsonValue)
        } catch (e) {
            this.toastrService.error('Verkeerde content', 'Niet gelukt')
        }
    }
}
